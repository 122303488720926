.header {
  position: relative;
  padding-top: rem(5px);
  padding-bottom: rem(5px);
  padding-inline: rem(16px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 20;
  box-shadow: none;
  background-color: $body-blue;
  transition: box-shadow 0.2s;
  @include for-tablet-layout {
    padding-top: rem(14px);
    padding-bottom: rem(13px);
    padding-inline: rem(30px);
  }
  &.isScrolled {
    box-shadow: 0px 7px 5px -5px rgba(0, 0, 0, 0.2);
  }
  // &.feedback-header {
  //   background: #2270bc;
  // }
  P {
    margin: 0;
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: rem(24px);
    color: #fff;
    @include for-tablet-layout {
      font-size: rem(36px);
    }
  }
  .header-row {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 0;
      cursor: pointer;
    }
    .header-btn-close {
      display: flex;
      align-items: center;
      height: rem(38px);
      width: rem(38px);
      img {
        height: rem(16px);
        width: rem(16px);
        @include for-tablet-layout {
          height: rem(25px);
          width: rem(25px);
        }
      }
    }
    .go-back-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: rem(38px);
      width: rem(38px);
      @include for-tablet-layout {
        height: rem(60px);
        width: rem(60px);
      }
      img {
        height: rem(20px);
        width: rem(10px);
        @include for-tablet-layout {
          height: rem(30px);
          width: rem(15px);
        }
      }
    }
    .header-btn-action {
      img {
        height: rem(38px);
        width: rem(38px);
        @include for-tablet-layout {
          height: rem(60px);
          width: rem(60px);
        }
      }
      &.hide {
        visibility: hidden;
      }
    }
  }
}
