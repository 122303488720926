.tab-switcher {
  top: calc(env(safe-area-inset-top, -rem(62px)) + rem(62px));
  max-width: rem(728px); // tablet-width
  @include for-tablet-layout {
    top: rem(107px);
  }
  display: flex;
  justify-content: center;
  border-radius: 25px;
  width: 94.6%;
  position: fixed;
  z-index: 5;
  box-shadow: none;
  background-color: #0f5395;
  @include for-tablet-layout {
    border-radius: 40px;
  }

  &.isScrolled {
    box-shadow: -2px 3px 5px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
  }

  &__wrap {
    display: inline-flex;
    border-radius: 25px;
    height: rem(50px);
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    @include for-tablet-layout {
      height: rem(80px);
      border-radius: 40px;
    }

    &.isScrolled {
      background: none;
      transition: all 0.2s;
    }
  }

  &__btn {
    color: rgba(255, 255, 255, 0.65);
    border: 2px solid transparent;
    border-radius: 25px;
    outline: none;
    font-weight: 400;
    font-size: rem(19px);
    line-height: 23px;
    width: 50%;
    &.smaller-font {
      font-size: rem(17px);
    }
    @include for-tablet-layout {
      font-size: rem(29px);
      &.smaller-font {
        font-size: rem(29px);
      }
    }

    &-active {
      color: #fff;
    }
    transition: 0.4s;
  }
  .active-indicator {
    transition: left 0.2s ease-in;
    border-radius: 25px;
    width: 50%;
    border: 2px solid #02a7cc;
    color: #fff;
    background-color: none;
    position: absolute;
    left: 0;
    height: 100%;
    @include for-tablet-layout {
      border-radius: 40px;
      border: 3px solid #02a7cc;
    }
    &.booked {
      left: 50%;
    }
  }
  .red-indicator {
    height: rem(15px);
    width: rem(15px);
    background: #ff0000;
    border-radius: 50%;
    position: absolute;
    right: rem(15px);
    top: rem(-5px);
    @include for-tablet-layout {
      height: rem(22px);
      width: rem(22px);
    }
  }
}
