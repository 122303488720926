.classroom-viewport {
  min-height: 100% !important;
  min-width: 100% !important;
}

.header-actions > svg {
  margin-left: 10px !important;
}

//  .modal, .modal-content {
//      width: 100% !important;
//  }

.pretest {
  width: 100% !important;
  display: block !important;
}

.netless-window-manager-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.fcr-layout-header {
  justify-content: unset !important;
}

.fcr-biz-header-title-wrap > div {
  padding: 0 23px !important;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // width: 93px;
  // padding: 0 0 0 25px !important;
  &.fcr-biz-header-split-line {
    padding: unset !important;
    width: auto;
  }
}

.w-screen {
  width: 100%;
}
.h-screen {
  height: 100%;
}

.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-session {
  color: $base-txt-white;
  font-size: rem(24px);
  p {
    transform: translate(-10%, -20%);
  }

  @include for-tablet-layout {
    font-size: rem(32px);
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: $base-txt-white;
  color: $base-txt-white;
  box-shadow: 9999px 0 0 -5px $base-txt-white;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
  @include for-tablet-layout {
    width: 10px;
    height: 10px;
  }
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: $base-txt-white;
  color: $base-txt-white;
  @include for-tablet-layout {
    width: 10px;
    height: 10px;
  }
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px $base-txt-white;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px $base-txt-white;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px $base-txt-white;
  }
  30% {
    box-shadow: 9984px 0 0 2px $base-txt-white;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px $base-txt-white;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px $base-txt-white;
  }
  30% {
    box-shadow: 9999px 0 0 2px $base-txt-white;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px $base-txt-white;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px $base-txt-white;
  }
  30% {
    box-shadow: 10014px 0 0 2px $base-txt-white;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px $base-txt-white;
  }
}
