.welcome {
  &:focus-visible {
    outline: none;
  }
  &__title {
    margin-bottom: rem(35px);
    font-weight: 700;
    font-size: rem(21px);
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.36px;
    color: #ffffff;
    @include for-tablet-layout {
      font-size: rem(32px);
      margin-bottom: rem(45px);
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin-bottom: rem(60px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: rem(30px);
    @include for-tablet-layout {
      gap: rem(40px);
    }
    &-item {
      position: relative;
      font-size: rem(17px);
      line-height: 120%;
      color: #ffffff;
      @include for-tablet-layout {
        font-size: rem(26px);
      }
      .list-info-item {
        display: flex;
        gap: rem(10px);
        img.list-icon-img {
          align-self: flex-start;
          height: rem(24px);
          width: rem(24px);
          @include for-tablet-layout {
            height: rem(36px);
            width: rem(36px);
          }
        }
      }
    }
  }
}
