.feedback-question-container {
  color: $base-txt-white;
  font-size: rem(21px);
  line-height: rem(25.2px);
  padding: 0 rem(20px);
  @include for-tablet-layout {
    font-size: rem(32px);
    line-height: rem(38px);
  }
  p {
    margin: 0;
  }
  .feedback-question {
    display: flex;
    flex-direction: column;
    .feedback-question-txt {
      height: rem(76px);
      font-weight: 300;
      padding: 0 rem(10px);
    }
    .feedback-question-btns {
      display: flex;
      gap: rem(2px);
      margin-top: rem(15px);
      margin-bottom: rem(40px);
      @include for-tablet-layout {
        margin-top: rem(50px);
        margin-bottom: rem(50px);
      }
    }
    textarea.feedback-question-textarea {
      height: rem(96px);
      background: rgba(0, 0, 0, 0.2);
      padding: rem(10px);
      font-size: rem(22px);
      line-height: rem(25.2px);
      border: none;
      @include for-tablet-layout {
        height: rem(136px);
        padding: rem(10px);
        font-size: rem(32px);
        line-height: rem(38.4px);
      }
    }
  }
  button {
    background: rgba(0, 0, 0, 0.2);
    width: rem(96px);
    height: rem(50px);
    transition: all 0.2s;
    & > * {
      height: rem(36px);
      width: rem(36px);
    }
    @include for-tablet-layout {
      width: rem(144px);
      height: rem(75px);

      & > * {
        height: rem(54px);
        width: rem(54px);
      }
    }
    &.left-btn {
      border-radius: 50px 0 0 50px;
    }
    &.right-btn {
      border-radius: 0 50px 50px 0;
    }
    &.active {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
    }
    &.lower-opacity {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 0;
      opacity: 0.4;
    }
  }
}
