.terms-and-conditions {
  border: none;
  height: 100%;
  width: 100%;
  color: $base-txt-white;
  background: $body-blue;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding-bottom: rem(50px);
  @include for-tablet-layout {
    padding-bottom: rem(100px);
  }
  .terms-iframe-container {
    height: 100%;
    iframe {
      border: none;
      height: 100%;
      width: 100%;
    }
  }
}
