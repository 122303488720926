// font-size
$font-size-html: 16px;

// color palette
$body-blue: #0f5395;
$card-blue-bg: #297bcc;
$base-txt-white: #fff;
$primary-orange: #f26c45;

$success: #00b821;

// Breakpoints
$mobile-breakpoint: 460px;
$tablet-breakpoint: 768px;

// global heights
$sessions__wrap-padding-top-mobile: rem(122px);
$sessions__wrap-padding-top-tablet: rem(207px);

$tab-switcher-height-mobile: rem(50px);
$tab-switcher-height-tablet: rem(80px);
