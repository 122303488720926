.beta-tag {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  height: rem(30px);
  width: rem(55px);
  padding: rem(3.5px) rem(7px);
  background: #f10000;
  border-radius: 2px 10px;
  color: $base-txt-white;
  font-size: rem(15px);
  p {
    margin: 0;
  }
  @include for-tablet-layout {
    font-size: rem(23px);
    height: rem(45px);
    width: rem(82.5px);
  }
}
