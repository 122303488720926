* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  font-size: $font-size-html;
  background: $body-blue;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  user-select: none;
  -webkit-touch-callout: none;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
  box-sizing: content-box;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

textarea {
  width: 100%;
  resize: none;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: $base-txt-white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: rem(17px);
  line-height: rem(20.4px);
  padding: rem(10px);
  border: none;
  &::placeholder {
    color: $base-txt-white;
    opacity: 0.5;
  }
  border: transparent 1px solid;
  &:focus-visible {
    outline: none;
    border: #02a7cc 1px solid;
  }
  @include for-tablet-layout {
    border-radius: 15px;
    font-size: rem(24px);
    padding: rem(20px) rem(20px) rem(30px);
  }
}

input {
  border: transparent 1px solid;
  &:focus-visible {
    outline: none;
    border: #02a7cc 1px solid;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bodyWrapper {
  overflow-y: auto;
  height: 100%;
  padding-bottom: rem(50px);
  @include for-tablet-layout {
    padding-bottom: rem(100px);
  }
}

.loaderWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.terms-and-conditions-div {
  z-index: 40;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
  background: $body-blue;
}
