.bottom-confirmation-popup {
  max-width: rem(708px);
  background: #153561;
  position: fixed;
  bottom: rem(-60px);
  transform: translateY(100%);
  width: 92%;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  border-radius: 10px;
  padding: rem(10px) rem(15px);
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  &.active {
    transform: translateY(0%);
    margin: auto;
    z-index: 10;
    bottom: rem(24px);
    @include for-tablet-layout {
      bottom: rem(24px);
      border-radius: 15px;
    }
  }
  .bottom-confirmation-popup-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(8px);
    img.popup-icon {
      display: block;
      height: rem(34px);
      width: rem(34px);
      @include for-tablet-layout {
        height: rem(52px);
        width: rem(52px);
      }
    }

    p.popup-msg {
      color: #fff;
      margin: 0;
      font-size: rem(17px);
      @include for-tablet-layout {
        font-size: rem(26px);
      }
    }
  }
}
