.sessions__wrap {
  padding: rem(74px) rem(10px) 0;
  @include for-tablet-layout {
    padding: rem(124px) rem(20px) 0;
  }
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &.no-items {
    justify-content: center;
  }
  &.pre-class-wrap {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 30;
    top: 0;
    height: 100vh;
    padding-top: 0;
  }
}

.session {
  height: 300px;
  padding: 20px;
  border-radius: 18px;
  background-color: #297bcc;
  position: relative;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &__itemsWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: rem(728px); //item tablet-width
  }

  &__noItemsWrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(24px);
    * {
      text-align: center;
    }
  }

  .book-session-button {
    position: absolute;
    right: 10px;
    bottom: 35px;
    background-color: #f26c45;
    color: #ffffff;
    cursor: pointer;
    width: 35%;
    height: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .cancel-session-button {
    position: absolute;
    left: 5%;
    bottom: 2%;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.36px;
    text-decoration-line: underline;
  }

  .booked-session {
    position: absolute;
    top: 3%;
    right: 10px;
    border-radius: 100px;
    background-color: #00b821;
    width: 16%;
    font-weight: 900;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    max-width: 60px;
  }

  .tutor {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 63%;

    .tutor-name {
      width: 60%;
    }
  }
}

.cancel-session-button {
  position: absolute;
  color: #ffffff;
  left: 5%;
  bottom: 2%;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.36px;
  text-decoration-line: underline;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.05);
}

.classroom-viewport {
  min-height: 100% !important;
  min-width: 100% !important;
}

.header-actions > svg {
  margin-left: 10px !important;
}

.modal {
  height: 100%;
  overflow: scroll;
}

.pretest {
  width: 100% !important;
  display: block !important;
}

.netless-window-manager-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.fcr-layout-header {
  justify-content: unset !important;
}

.fcr-biz-header-title-wrap > div {
  padding: 0 23px !important;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // width: 93px;
  // padding: 0 0 0 25px !important;
  &.fcr-biz-header-split-line {
    padding: unset !important;
    width: auto;
  }
}

.w-screen {
  width: 100% !important;
}
.h-screen {
  height: 100% !important;
}

.orange-btn {
  background-color: #f97316;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 10px 45px;
  border-radius: 100px;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.inline-flex {
  display: inline-flex;
}

.h-9 {
  height: 2.25rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-t-3xl {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.center-height {
  height: 80vh;
  align-items: center;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 10px;
}

.rounded-br-3xl {
  border-bottom-right-radius: 10px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-white {
  --tw-border-opacity: 0.05;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-\[\#EA4040\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 64 64 / var(--tw-bg-opacity));
}

.bg-orange-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(242 108 69 / var(--tw-bg-opacity));
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.align-center {
  align-items: center;
}

.mr-10 {
  margin-right: 10px;
}

.bg-btn {
  background-color: #1e427e80;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bg-transparent {
  background-color: transparent;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.text-md {
  font-size: 1rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-orange-300 {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upcoming {
  &__item {
    padding: 16px;

    &-head {
      margin-bottom: 16px;
    }

    &-main {
      margin-bottom: 16px;
    }

    &-title {
      font-size: 21px;
      line-height: 120%;
    }

    &-subtitle {
      font-size: 15px;
      line-height: 120%;
    }
  }
}
