.ellipsis {
  //2-line ellipsis:
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  //
}

.italic {
  font-style: italic;
}

.flex {
  display: flex;

  > *.full {
    flex: 1;
  }
  &.column {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
  }
  &.justify-start {
    justify-content: start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: end;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-evenly {
    justify-content: space-evenly;
  }
  &.wrap {
    flex-wrap: wrap;
  }
}

.cta-btn {
  background-color: $primary-orange;
  color: #fff;
  width: fit-content;
  align-self: center;
  font-size: rem(24px);
  line-height: rem(25px);
  padding: rem(12.5px) rem(38px);
  border-radius: 100px;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
  font-weight: normal;
  @include for-tablet-layout {
    font-size: rem(32px);
    line-height: rem(38px);
    padding: rem(18.5px) rem(57px);
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.1);
  }
}

button.welcome-modal-i-btn {
  background-color: #1e427e;
  background-image: url(../../image/info-icon.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 50%;
  height: 2.25rem;
  width: 2.25rem;
  z-index: 5;
  position: fixed;
  right: rem(14px);
  bottom: rem(14px);
  box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.25);
  @include for-tablet-layout {
    height: rem(50px);
    width: rem(50px);
    right: calc((100% - 728px) / 2);
    bottom: rem(25px);
  }
}

.capitalize {
  text-transform: capitalize;
}
