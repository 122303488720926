.post-session-screen {
  height: 90%;
  display: block;
  color: $base-txt-white;
  padding: rem(20px) rem(10px) 0;
  p {
    margin: 0;
    font-size: rem(19px);
    line-height: rem(22.8px);
  }
  .post-session-survey {
    height: 100%;
    margin: 0 auto;
    @include for-tablet-layout {
      margin: rem(80px) auto 0;
      max-width: rem(568px);
      font-size: rem(23px);
    }
    .survey-questions-container {
      gap: rem(30px);
      .survey-question-container {
        p {
          align-self: flex-start;
          @include for-tablet-layout {
            font-size: rem(23px);
          }
        }
        gap: rem(15px);
        @include for-tablet-layout {
          gap: rem(50px);
        }
        button {
          img {
            height: rem(25px);
            width: rem(25px);
          }
          @include for-tablet-layout {
            padding: rem(12.5px) rem(47px);
            img {
              height: rem(37px);
              width: rem(37px);
            }
          }
        }
        .survey-question-btns {
          width: 100%;
          justify-content: space-evenly;
          button {
            height: rem(50px);
            width: rem(100px);
            cursor: pointer;
            &.success {
              background-color: $success;
            }
          }
        }
        .survey-question-textarea {
          max-width: rem(500px);
          border: transparent 1px solid;
          &:focus-visible {
            outline: none;
            border: #02a7cc 1px solid;
          }
        }
      }
    }
    .submit-btn-container {
      padding: rem(30px) 0 rem(50px);
      transition: all 0.2s;
      &.larger-padding {
        padding: rem(100px) 0 rem(50px);
      }
      .submit-btn {
        align-self: center;
      }
    }
  }
}
