/* Breakpoint mixins */

@mixin for-mobile-layout {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin for-tablet-layout {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}
