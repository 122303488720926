.android {
  display: flex;
  flex-direction: column;
  padding: rem(30px) rem(25px);
  gap: rem(30px);
  &.modal {
    &-panel {
      background: white;
      backdrop-filter: blur(97.8581px);
      border-radius: 5px;
      max-width: 80%;

      &__error {
        min-width: 270px;
      }
    }

    &-opacityPanel {
      max-width: 350px;
      background: rgba(0, 0, 0, 0.75);
      border-radius: 10px;
      padding: 25px 12px;

      .modal-content__title {
        color: #fff;
        margin: 0 0 0 6px;
      }

      .modal-content {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
  .modal-content-android {
    .title {
      font-family: Roboto-Regular;
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      color: #000000;
      text-align: start;
      margin: 0;
    }
  }

  .modal-footer-android {
    display: flex;
    justify-content: flex-end;
  }

  .modal-btn-android {
    font-family: Roboto-Regular;
    font-weight: 500;
    font-size: rem(21px);
    text-align: center;
    color: #019688;
    text-transform: uppercase;
  }
}

.modal {
  &-panel {
    background: rgba(255, 255, 255, 0.77);
    backdrop-filter: blur(97.8581px);
    border-radius: 13px;
    max-width: 270px;

    &__error {
      min-width: 270px;
    }
  }

  &-opacityPanel {
    max-width: 350px;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 25px 12px;

    .modal-content__title {
      color: #fff;
      margin: 0 0 0 6px;
    }

    .modal-content {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &-content {
    padding: 30px 25px;

    &__title {
      font-weight: 500;
      font-size: 17px;
      line-height: 120%;
      text-align: center;
      color: #000000;

      &-error {
        color: #ff3333;
      }
    }
  }

  &-footer {
    border-top: 1px solid rgba(60, 60, 67, 0.36);
    display: flex;
  }

  &-btn {
    flex: 1;
    padding: 10px 0;
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    text-align: center;

    &:not(:last-child) {
      border-right: 1px solid rgba(60, 60, 67, 0.36);
    }

    &__danger {
      color: #ea4040;
    }

    &__default {
      color: #007aff;
    }
  }

  &__welcome {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: rem(570px);
    width: 100%;
    padding: rem(60px) rem(20px) rem(30px);
    margin: 0 10px;
    background: linear-gradient(180deg, #2270bc 0%, #1a5497 99.77%);
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative; //for beta-tag
    &:focus-visible {
      outline: none;
    }
    @include for-tablet-layout {
      height: 67%;
      border-radius: 15px;
      padding: rem(96.5px) rem(50px);
    }
  }
}

.large-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: rem(570px);
  width: 100%;
  padding: rem(60px) rem(20px) rem(30px);
  margin: 0 10px;
  background: linear-gradient(180deg, #2270bc 0%, #1a5497 99.77%);
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative; //for beta-tag
  @include for-tablet-layout {
    height: 67%;
    padding: rem(96.5px) rem(50px);
  }
}
