.class-card-container {
  margin-bottom: rem(10px);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: -3px 3px 8px 2px rgb(0 0 0 / 10%);
  @include for-tablet-layout {
    margin-bottom: rem(20px);
    border-radius: 15px;
  }
}

.class-card {
  overflow: hidden;
  background: $card-blue-bg;
  color: $base-txt-white;
  padding: rem(15px);
  border-radius: 10px;
  @include for-tablet-layout {
    padding: rem(20px);
    border-radius: 15px;
  }
  .card-header {
    padding-bottom: rem(15px);
    gap: rem(15px);
    align-items: center;
    @include for-tablet-layout {
      gap: rem(20px);
      padding-bottom: rem(20px);
    }
    .avatar-container {
      pointer-events: none;
      user-select: none;
      max-height: rem(54px);
      max-width: rem(54px);
      @include for-tablet-layout {
        max-height: rem(100px);
        max-width: rem(100px);
      }
    }
    .card-title {
      padding: rem(4px) 0 rem(2px);
      font-weight: bold;
      font-size: rem(21px);
      line-height: rem(25.2px);
      @include for-tablet-layout {
        padding: rem(15px) 0 rem(4px);
        font-size: rem(32px);
        line-height: rem(38px);
      }
    }
    .card-subtitle {
      font-size: rem(15px);
      @include for-tablet-layout {
        font-size: rem(23px);
      }
    }
  }

  .divider {
    height: rem(1px);
    @include for-tablet-layout {
      height: rem(2px);
    }
  }

  .card-main {
    display: flex;
    flex-direction: column;
    padding-top: rem(15px);
    font-size: rem(15px);
    gap: rem(10px);
    @include for-tablet-layout {
      padding-top: rem(20px);
      font-size: rem(23px);
      gap: rem(24px);
    }
    .class-info-item {
      display: flex;
      gap: rem(10px);
      @include for-tablet-layout {
        gap: rem(20px);
      }
      &.class-level {
        font-weight: bold;
      }

      .warning {
        font-weight: bold;
        color: #ffe680; //yellow-light
        p {
          margin: 0;
        }
      }

      img.card-icon-img {
        height: rem(25px);
        width: rem(25px);
        @include for-tablet-layout {
          height: rem(37px);
          width: rem(37px);
        }
      }
    }
  }
  .class-description {
    padding-top: rem(5px);
    white-space: pre-wrap;

    @include for-tablet-layout {
      padding-top: rem(15px);
    }

    p {
      margin: 0;
    }
    .hidden {
      display: none;
    }
    .see-more {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .class-action-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: rem(20px);
    @include for-tablet-layout {
      padding-top: rem(35px);
    }
    .card-cta {
      min-width: 40%;
    }
    .class-cancel-btn {
      font-size: rem(15px);
      color: $base-txt-white;
      text-decoration: underline;
      height: rem(50px);
      @include for-tablet-layout {
        height: rem(75px);
        font-size: rem(23px);
      }
    }

    .session-booked-msg {
      font-size: rem(24px);
      color: $base-txt-white;
      filter: opacity(60%);
    }
  }
}
