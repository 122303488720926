.post-session-screens {
  background: linear-gradient(180deg, $body-blue 0%, #2270bc 30%, $body-blue 99.77%);
  padding-top: rem(10px);
  @include for-tablet-layout {
    padding-top: rem(24px);
  }
  color: $base-txt-white;
  button.cta-btn {
    width: 60%;
    transition: all 0.2s;
    @include for-tablet-layout {
      width: 50%;
    }
    &.cta-hide {
      background: transparent;
      box-shadow: none;
      color: transparent;
    }
  }
}
