.progress-bar-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 rem(20px);
  padding: rem(30px) 0 rem(50px);
  @include for-tablet-layout {
    padding: rem(40px) 0 rem(50px);
  }
  .progress-bar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    .emptyProgressBar {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      height: rem(9px);
      @include for-tablet-layout {
        height: rem(14px);
      }
      overflow: hidden;
      position: relative;

      .fillingProgressBar {
        background-color: $primary-orange;
        border-radius: 100px;
        height: 100%;
        width: 100%;
        position: absolute;
        transition: all 1s;
      }
    }

    .checkpoints {
      z-index: 5;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: space-between;
      .checkpoint {
        color: $base-txt-white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(25px);
        width: rem(25px);
        @include for-tablet-layout {
          height: rem(38px);
          width: rem(38px);
          font-size: rem(26px);
        }
        background: $body-blue;
        opacity: 0.9;

        border-radius: 50px;
        &.active {
          background-color: $primary-orange;
          border: 1px solid $base-txt-white;
        }
        .checkmark {
          height: rem(15px);
          width: rem(15px);
          @include for-tablet-layout {
            height: rem(23px);
            width: rem(23px);
          }
        }
      }
    }
  }
}
