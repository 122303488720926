.pre-class-screen {
  min-height: rem(575px);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 0 20px;
  align-items: center;
  background: linear-gradient(180deg, #2270bc 0%, #1a5497 99.77%);
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 5;
  width: 100%;
  @include for-tablet-layout {
    border-radius: 15px;
    padding: rem(96.5px) rem(50px);
    height: rem(737px);
  }
}

input {
  color: #ffffff;
}
.pre-class-header {
  position: absolute;
  top: rem(10px);
  left: rem(10px);

  .header-btn-close {
    display: flex;
    align-items: center;
    height: rem(38px);
    width: rem(38px);
    img {
      height: rem(16px);
      width: rem(16px);
      @include for-tablet-layout {
        height: rem(24px);
        width: rem(24px);
      }
    }
  }
}

.pre-class-record-disclaimer {
  opacity: 0.8;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  margin-bottom: rem(35px);
  .pre-class-info {
    margin-right: rem(10px);
    height: rem(20px);
    width: rem(32px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    white-space: nowrap;
    @include for-tablet-layout {
      height: rem(21px);
      width: rem(25px);
    }
  }
}

.pre-class-disc-terms {
  font-style: normal;
  font-weight: 400;
  font-size: rem(15px);
  line-height: rem(18px);

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.15px;

  color: rgba(255, 255, 255, 0.6);
  @include for-tablet-layout {
    font-size: rem(17px);
    line-height: rem(19px);
  }
  p {
    margin: 0;
  }
  .terms-and-conditions-link {
    font-size: rem(15px);
    line-height: rem(18px);
    color: rgba(255, 255, 255, 0.6);
    text-decoration: underline;
    padding: 0;
    @include for-tablet-layout {
      font-size: rem(17px);
      line-height: rem(19px);
    }
  }
}

.pre-class {
  position: relative;
  // height: 86%;
  width: 95%;

  @include for-tablet-layout {
    height: 67%;
    width: 70%;
    border-radius: 15px;
  }

  .pre-class-title {
    font-style: normal;
    font-weight: 400;
    font-size: rem(24px);
    line-height: rem(29px);
    text-align: center;
    letter-spacing: rem(0.07px);
    margin-bottom: rem(20px);
    margin-top: rem(30px);
    @include for-tablet-layout {
      font-size: rem(32px);
      line-height: rem(38px);
      margin-bottom: rem(20px);
      margin-top: 0;
    }
  }
  form {
    width: 100%;
  }
  .pre-class-description {
    font-style: normal;
    font-weight: 400;
    font-size: rem(21px);
    line-height: rem(25px);
    letter-spacing: rem(-0.36px);
    margin: 0 0 rem(45px) 0;
    @include for-tablet-layout {
      font-size: rem(26px);
      line-height: rem(25px);
    }
  }

  .pre-class-input-text {
    font-style: normal;
    font-weight: 274;
    font-size: rem(21px);
    line-height: rem(313.2px);
    letter-spacing: rem(-0.36px);
    width: 100%;
    margin-bottom: rem(10px);
  }

  .pre-class-input-mail-error {
    background: #ea4040;
    width: rem(315px);
    height: rem(12px);
    font-style: normal;
    font-weight: 400;
    font-size: rem(9px);
    line-height: rem(10px);
    display: flex;
    align-items: center;
    letter-spacing: -0.0777914px;
    color: #ffffff;
  }

  .pre-class-input {
    background: #1e4a87;
    color: #ffffff;
    mix-blend-mode: normal;
    border: 0;
    border-radius: rem(10px);
    width: 100%;
    margin-bottom: rem(45px);
    padding-left: rem(10px);
    font-size: rem(21px);
    height: rem(45px);
    @include for-tablet-layout {
      font-size: rem(26px);
      line-height: rem(25px);
      padding: rem(10px) rem(15px);
    }

    &.bad-mail-format {
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.2);
      mix-blend-mode: normal;
      border: 1px solid #ea4040;
      border-radius: 0px 0px rem(10px) rem(10px);
    }
  }

  .pre-class-input::-webkit-input-placeholder {
    color: #ffffff;
    font-weight: 400;
    font-size: rem(21px);
    line-height: rem(25px);
    display: flex;
    align-items: center;
    letter-spacing: rem(-0.36px);
    opacity: 0.2;
    @include for-tablet-layout {
      font-size: rem(26px);
      line-height: rem(25px);
    }
  }

  .pre-class-join-btn {
    margin-top: rem(20px);
    opacity: 1;
    transition: opacity 0.2s ease-in;
    &.disabled {
      opacity: 0;
    }
    @include for-tablet-layout {
      margin: rem(20px) 0;
    }
  }

  .holding-screen-timer {
    font-style: normal;
    font-weight: 400;
    font-size: rem(19px);
    line-height: rem(28px);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: rem(-0.36px);
    margin-top: rem(19px);
    margin-bottom: rem(20px);
    @include for-tablet-layout {
      font-size: rem(26px);
      line-height: rem(29px);
      margin-bottom: 0;
    }
  }

  .holding-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    .holding-screen-description {
      font-style: normal;
      font-weight: 400;
      font-size: rem(21px);
      line-height: rem(25px);
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      letter-spacing: rem(-0.36px);
      margin-bottom: rem(60px);
      @include for-tablet-layout {
        font-size: rem(26px);
        line-height: rem(29px);
      }
    }
  }
}
